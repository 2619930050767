.blink {
  -webkit-animation-name: blinker;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;

  -moz-animation-name: blinker;
  -moz-animation-duration: 1s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;

  animation-name: blinker;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@-moz-keyframes blinker {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blinker {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blinker {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.bgBlink {
  -webkit-animation-name: bgBlinker;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;

  -moz-animation-name: bgBlinker;
  -moz-animation-duration: 1s;
  -moz-animation-timing-function: linear;
  -moz-animation-iteration-count: infinite;

  animation-name: bgBlinker;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@-moz-keyframes bgBlinker {
  0% {
    background-color: initial;
  }

  50% {
    background-color: yellow;
  }

  100% {
    background-color: initial;
  }
}

@-webkit-keyframes bgBlinker {
  0% {
    background-color: initial;
  }

  50% {
    background-color: yellow;
  }

  100% {
    background-color: initial;
  }
}

@keyframes bgBlinker {
  0% {
    background-color: initial;
  }

  50% {
    background-color: yellow;
  }

  100% {
    background-color: initial;
  }
}

table.eod {
  border-collapse: collapse;
}

table.eod thead th {
  word-wrap: normal;
  color: white;
  border: 2px black solid;
  padding: 4px;
}

table.eod td {
  padding: 4px 8px;
}

table.eod td,
table.eod th {
  text-align: center;
  border: 1px black solid;
  padding: 2px 8px;
}

tr.highlight:hover td,
tr.highlight:hover th {
  border-top: 2px red solid !important;
  border-bottom: 2px red solid !important;
  background-color: lightpink !important;
  cursor: crosshair;
}

table tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

table.eod td {
  white-space: nowrap;
}

table.eod tr td:last-child,
table.eod tr th:last-child {
  border-right: 2px black solid;
}

table.eod tr td {
  border: 1px black solid;
}

.spacer {
  padding: 0px !important;
  width: 4px !important;
}

.cal:last-child {
  border-bottom-left-radius: 8px;
}

.section {
  border: 2px black solid !important;
  color: white;
}

tbody.month tr:first-child th:first-child {
  border-top-left-radius: 8px !important;
}

tbody:last-child tr:first-child th:first-child {
  border-bottom-left-radius: 8px !important;
}

.section span {
  padding: 2px 8px;
  writing-mode: vertical-lr;
}

.eod tr {
  height: 24px;
}

.eod tbody th {
  white-space: nowrap;
  padding: 4px 0px;
}

.eod tbody .label {
  padding: 2px 8px !important;
}

table.eod tbody tr:last-child td,
table.eod tbody tr:last-child th {
  border-bottom: 2px black solid;
}

table.eod tbody tr td:last-child {
  border-right: 2px black solid;
}

table.eod thead tr:first-child th {
  border-top: 2px black solid;
}

table.eod thead tr:last-child th {
  border-bottom: 2px black solid;
}

table.eod tbody:last-child tr:last-child {
  border-bottom-right-radius: 8px;
}

table.eod tbody:last-child tr:last-child .bid {
  border-bottom-left-radius: 8px;
}

.bid,
table.eod thead tr:first-child th:first-child {
  border-left: 2px black solid !important;
}

.ask {
  border-right: 2px black solid !important;
}

.eod tbody tr:last-child td,
.eod tbody tr:last-child th {
  border-bottom: 2px black solid;
}

/*! ******************** MONTH  *********************/

.month tr:nth-child(even) th {
  background: #c6e0b4;
}

.month tr .section {
  background: #548235 !important;
}

.month tr:nth-child(odd) th {
  background: #a9d08e;
}

.month tr:nth-child(even) .settle {
  background: #e2efda;
}

/*! ******************** QUARTER  *********************/

.quarter tr:nth-child(even) th {
  background: #8ea9db;
}

.quarter tr:nth-child(odd) th {
  background: #b4c6e7;
}

.quarter tr .section {
  background: #305496 !important;
}

.quarter tr:nth-child(odd) .settle {
  background: #ddebf7;
}

/*! ******************** CAL  *********************/

.cal tr:nth-child(odd) th {
  background: #ffe699;
}

.cal tr:nth-child(even) th {
  background: #ffd966;
}

.cal tr .section {
  background: #bf8f00 !important;
}

.cal tr:nth-child(even) .settle {
  background: #fff2cc;
}

/*! ******************** COMMON (EVEN)  *********************/

tr:nth-child(even) .grey {
  background: #d0cece;
}

tr:nth-child(even) .bid {
  background: #3333ff;
}

tr:nth-child(even) .ask {
  background: red;
}

/*! ******************** COMMON (ODD)  *********************/

tr:nth-child(odd) .grey {
  background: #aeaaaa;
}

tr:nth-child(odd) .bid {
  background: #003399;
}

tr:nth-child(odd) .ask {
  background: #c00000;
}

/*! ******************** COMMON   *********************/
.settle {
  background: white;
}

.bid-header {
  background-color: #3333ff;
  color: azure;
}

.ask-header {
  background-color: red;
  color: azure;
}

.askVolume,
.bidVolume {
  border-left: 2px black solid !important;
  border-right: 2px black solid !important;
}

tr:nth-child(odd) .bidVolume {
  background: #305496;
}

tr:nth-child(odd) .askVolume {
  background: #96454d;
}

tr:nth-child(even) .bidVolume {
  background: #8ea9db;
}

tr:nth-child(even) .askVolume {
  background: #d4868e;
}

.bid,
.ask,
.date-cell,
.section {
  color: white;
}

.purple-header {
  background: #7030a0;
  height: 24px;
}

.purple-header:first-child:first-child {
  border-top-left-radius: 8px;
}

.purple-header:last-child {
  border-top-right-radius: 8px;
}

.column-header {
  background: #3a3838;
}

.negative-value {
  background: #ff9999;
  color: #9c0006;
}

.positive-value {
  background: #e2efda;
  color: #006100;
}

.date-cell {
  background: black;
  border-top-left-radius: 8px;
}

.rhbb {
  border-right: 2px black solid !important;
}

.lhbb {
  border-left: 2px black solid !important;
}
