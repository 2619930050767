/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 2, 2022 */



@font-face {
    font-family: 'Centra No 2 Medium';
    src: url('centrano2-medium-webfont.woff2') format('woff2'),
        url('centrano2-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    descent-override: 200%;

}




@font-face {
    font-family: 'Centra No 2 Medium, Italic';
    src: url('centrano2-mediumitalic-webfont.woff2') format('woff2'),
        url('centrano2-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'Centra No 2 Medium';
    src: url('centrano2-medium-webfont.woff2') format('woff2'),
        url('centrano2-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    ascent-override: 100%;

}




@font-face {
    font-family: 'Centra No 2 Medium, Italic';
    src: url('centrano2-mediumitalic-webfont.woff2') format('woff2'),
        url('centrano2-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}